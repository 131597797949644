import React, { useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import {
	createHealthCondition as createHealthConditionAction,
	removeHealthCondition as removeHealthConditionAction,
	updatePatientHealthHistory as updatePatientHealthHistoryAction,
} from '../../redux/actions/patient';
import api from '../../services/api';
import { IconSVG } from '../icon';
import i18n from 'i18next';

const useConditionSuggestions = () => {
	const [conditions, setConditions] = useState([]);

	const fetchConditionSuggestions = async (token, search) => {
		const response = await api.fetchConditionsSuggestions(token, search);
		if (response.ok) {
			setConditions(response.data.clinical_concepts);
		} else {
			setConditions([]);
		}
	}

	return [conditions, fetchConditionSuggestions];
}

let bodyMetrics = {};

const HealthConditions = props => {
	const [
		conditionSuggestions,
		fetchConditionSuggestions,
	] = useConditionSuggestions();

	const bodyMetricsSubmit = useRef(null);
	const triggerBodyMetricsSubmit = () => {
		bodyMetricsSubmit.current.click();
	}

	const {
		healthHistory, 
		createHealthCondition, 
		removeHealthCondition, 
		updatePatientHealthHistory,
		submitButtonText,
		users,
		token,
		history,
	} = props;
	const {
		health_conditions: healthConditions,
		has_medical_conditions: hasMedicalConditions,
	} = healthHistory;

	const patientId = useSelector((state) => state.telehealth.user.id);
	const currentpatient = users && users.find(i => i.user.id === patientId);
	const sharable_user_data = currentpatient && currentpatient.sharable_user_data;

	return (
		<div>
			<div className="app-content-block-header">
				<h1>{ i18n.t('LABEL.components.forms.health-conditions.header_body_metrics', 'Body metrics') }</h1>
			</div>
			<div className="app-content-block-body body-metrics health-conditions">
				<Formik
					initialValues={{
						height_feet: sharable_user_data ? sharable_user_data.height_feet : "",
						height_inches: sharable_user_data ? sharable_user_data.height_inches : "",
						weight: sharable_user_data ? sharable_user_data.weight : "",
					}}
					validate={(values) => {
						const errors = {};
						const height_feet = +values.height_feet;
						if (!height_feet) {
							errors.height_feet_required = i18n.t('LABEL.components.forms.health-conditions.height_feet_required', 'Height Feet is required');
						} else if (height_feet > 10 || height_feet < 0 || ('' + values.height_feet).includes('.')) {
							errors.height_feet_invalid = i18n.t('LABEL.components.forms.health-conditions.height_feet_invalid', 'Height Feet is invalid');
						}

						const height_inches = +values.height_inches;
						if (height_inches > 11 || height_inches < 0 || ('' + values.height_inches).includes('.')) {
							errors.height_inches_invalid = i18n.t('LABEL.components.forms.health-conditions.height_inches_invalid', 'Height Inches is invalid');
						}

						const weight = +values.weight;
						if (!weight) {
							errors.weight_required = i18n.t('LABEL.components.forms.health-conditions.weight_required', 'Weight is required');
						} else if (weight > 999 || weight < 0 || ('' + values.weight).includes('.')) {
							errors.weight_invalid = i18n.t('LABEL.components.forms.health-conditions.weight_invalid', 'Weight is invalid');
						}

						bodyMetrics = { ...values, valid: Object.keys(errors).length === 0 };

						return errors;
					}}
					onSubmit={async (values, {resetForm}) => {
						// resetForm({})
					}}
				>
					{({ errors, handleBlur, handleChange, setFieldValue }) => (
						<Form>
							<div className="input-container row">
								<div className="height-metrics">
									{ errors && (errors.height_feet_required || errors.height_feet_invalid || errors.height_inches_invalid)
										? (
											<label htmlFor="height_feet" className="error">
												{ i18n.t('LABEL.components.forms.health-conditions.height-label', 'Height') }
												<span className="required-mark">&nbsp;*</span>
											</label>
										  )
										: (
											<label htmlFor="height_feet">
												{ i18n.t('LABEL.components.forms.health-conditions.height-label', 'Height') }
												<span className="required-mark">&nbsp;*</span>
											</label>
										  )
									}
									<div className="body-metrics-inputs">
										<Field
											type="text"
											name="height_feet"
											onChange={e => {
												handleChange(e);
											}}
											onBlur={handleBlur}
											autoComplete="off"
										/>
										<span className="unit">
											{ i18n.t('LABEL.components.forms.health-conditions.height-ft', 'ft.') }
										</span>
										<Field
											type="text"
											name="height_inches"
											onChange={e => {
												handleChange(e);
											}}
											onBlur={handleBlur}
											autoComplete="off"
										/>
										<span className="unit">
											{ i18n.t('LABEL.components.forms.health-conditions.height-in', 'in.') }
										</span>
									</div>
								</div>
								<div className="weight-metric">
									{ errors && (errors.weight_required || errors.weight_invalid)
										? (
											<label htmlFor="weight" className="error">
												{ i18n.t('LABEL.components.forms.health-conditions.weight-label', 'Weight') }
												<span className="required-mark">&nbsp;*</span>
											</label>
										  )
										: (
											<label htmlFor="weight">
												{ i18n.t('LABEL.components.forms.health-conditions.weight-label', 'Weight') }
												<span className="required-mark">&nbsp;*</span>
											</label>
										  )
									}
									<div className="body-metrics-inputs">
										<Field
											type="text"
											name="weight"
											onChange={e => {
												handleChange(e);
											}}
											onBlur={handleBlur}
											autoComplete="off"
										/>
										<span className="unit">
											{ i18n.t('LABEL.components.forms.health-conditions.weight-lb', 'lb.') }
										</span>
									</div>
								</div>
							</div>
							<div className="body-metrics-errors">
								{/* errors.height_feet_required ? (<div className="error">{errors.height_feet_required}</div>) : '' */}
								{/* errors.height_feet_invalid ? (<div className="error">{errors.height_feet_invalid}</div>) : '' */}
								{/* errors.height_inches_invalid ? (<div className="error">{errors.height_inches_invalid}</div>) : '' */}
								{/* errors.weight_required ? (<div className="error">{errors.weight_required}</div>) : '' */}
								{/* errors.weight_invalid ? (<div className="error">{errors.weight_invalid}</div>) : '' */}
							</div>

							<button type="submit" className="body-metrics-submit-btn" ref={bodyMetricsSubmit}>
								{ i18n.t('LABEL.components.forms.health-conditions.body-metrics-submit-btn', 'Validate body metrics') }
							</button>
						</Form>
					)}
				</Formik>
			</div>
			<br/>
			<div className="app-content-block-header">
				<h1>{ i18n.t('LABEL.components.forms.health-conditions.header', 'Do you have any health conditions?') }</h1>
			</div>
			<div className="app-content-block-body health-conditions">
				{healthConditions.length > 0 && (
					<div className="col">
						<div className="row">
							<div className="col50 header">
								{ i18n.t('LABEL.components.forms.health-conditions.condition', 'Condition') }
							</div>
							<div className="col30 header">
								{ i18n.t('LABEL.components.forms.health-conditions.source', 'Source') }
							</div>
							<div className="col10" />
						</div>
						{healthConditions.map(({ medical_condition: condition }) => (
							<div key={condition.id} className="row tableBody">
								<div className="col50">{condition.condition}</div>
								<div className="col30">{condition.source}</div>
								<div className="col10">
									<button
										className="clean"
										type="button"
										onClick={() => removeHealthCondition(condition.id)}
									>
										<IconSVG
											name="delete"
											cssClasses="delete"
										/>
									</button>
								</div>
							</div>
						))}
					</div>
				)}
				<Formik
					initialValues={{
						condition: "",
						source: "",
					}}
					onSubmit={async (values, {resetForm}) => {
						createHealthCondition({ medical_condition: values });
						resetForm({})
					}}
				>
					{({ handleChange, setFieldValue }) => (
						<Form>
							<div className="input-container input-container-single" style={{marginTop: 20}}>
								<label htmlFor="condition">
									{ i18n.t('LABEL.components.forms.health-conditions.condition-label', 'Condition') }
								</label>
								<div className="row center">
									<Field
										name="condition"
										onChange={e => {
											fetchConditionSuggestions(token, e.nativeEvent.target.value);
											handleChange(e);
										}}
										autoComplete="off"
									/>
									<button type="submit" style={{marginTop: 0}}>
										{ i18n.t('LABEL.components.forms.health-conditions.add-btn', 'Add') }
									</button>
									{conditionSuggestions.length > 0 && (
										<div className="suggestions-container">
											{conditionSuggestions.map(condition => (
												<div
													className="suggestion-item"
													key={condition.concept_id}
													onMouseDown={() => {
														setFieldValue("condition", condition.title);
													}}
												>
													<div><strong>{condition.title}</strong></div>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
			<div className="app-content-block-footer health-conditions">
				<div className="button-container" style={{justifyContent: 'space-between'}}>
					<button
						onClick={() => {
							history.go('application/reason-for-visiting');
						}}
						type="button"
						className="default"
						style={{marginTop: 15}}
					>
						{ i18n.t('LABEL.components.forms.health-conditions.back-btn', 'Back') }
					</button>
					<button
						onClick={() => {
							triggerBodyMetricsSubmit();
							if (!bodyMetrics.valid) {
								return;
							}
							updatePatientHealthHistory({
								personal_information: {
									height_feet: +bodyMetrics.height_feet,
									height_inches: +bodyMetrics.height_inches,
									weight: +bodyMetrics.weight
								},
								medical_history: {
									medical_conditions: healthConditions.length > 0,
								},
							});
							props.onSubmit();
						}}
						type="submit"
					>
						{ healthConditions.length > 0 ? submitButtonText : i18n.t('LABEL.components.forms.health-conditions.not-have-condition-btn', 'No, I do not have any health conditions.') }
					</button>
				</div>
			</div>
		</div>
	);
};

HealthConditions.propTypes = {
	submitButtonText: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	healthHistory: PropTypes.object.isRequired,
	createHealthCondition: PropTypes.func.isRequired,
	removeHealthCondition: PropTypes.func.isRequired,
	updatePatientHealthHistory: PropTypes.func.isRequired,
	users: PropTypes.any.isRequired,
	token: PropTypes.string.isRequired,
	history: PropTypes.any.isRequired
};

const mapStateToProps = state => ({
	healthHistory: state.patient.health_history,
	users: state.telehealth.users,
	token: state.telehealth.token,
});

const mapDispatchToProps = dispatch => ({
	createHealthCondition: healthCondition => dispatch(createHealthConditionAction(healthCondition)),
	removeHealthCondition: conditionId => dispatch(removeHealthConditionAction(conditionId)),
	updatePatientHealthHistory: healthHistory => dispatch(updatePatientHealthHistoryAction(healthHistory)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HealthConditions);
